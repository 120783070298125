// src/pages/LandingPage.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/modern-styles.css'; // Global styles

const LandingPage: React.FC = () => {
    const navigate = useNavigate();

    const handleNavigate = (path: string) => {
        navigate(path);
    };

    return (
        <div
            className="landing-container"
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh'
            }}
        >
            <div
                style={{
                    backgroundColor: "var(--primary-bg)",
                    padding: '32px',
                    borderRadius: 'var(--rounded)',
                    boxShadow: "var(--shadow)",
                    textAlign: 'center',
                    color: "var(--header-text)",
                    maxWidth: '500px',
                    width: '100%'
                }}
            >
                <h2 style={{ marginBottom: '16px' }}>Disclaimer</h2>
                <p style={{ fontSize: '16px', lineHeight: 1.5, marginBottom: '16px' }}>
                    This tool is meant to be used for educated betting. The presented probabilities and values do not claim
                    to be accurate. Users are responsible for using their own knowledge to bet accordingly.
                </p>
                <p style={{ fontSize: '16px', lineHeight: 1.5, marginBottom: '24px' }}>
                    By proceeding, you acknowledge that you have read and understood the above information.
                </p>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '16px' }}>
                    <button
                        className="modern-button outlined-button"
                        onClick={() => handleNavigate('/mlb')}
                    >
                        MLB
                    </button>
                    <button
                        className="modern-button outlined-button"
                        onClick={() => handleNavigate('/nba')}
                    >
                        NBA
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
