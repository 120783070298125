import React, { useState, useEffect, useCallback } from 'react';
import api from '../api/api';
import { useNavigate } from 'react-router-dom';
import '../styles/modern-styles.css';

// Define the NBA data type (fields from your API)
interface NBADataItem {
    _id: string;
    assists_last_10: number;
    assists_last_2: number;
    assists_last_5: number;
    best: boolean;
    ev: number;
    line: number;
    match: string;
    minutes_last_10: number;
    minutes_last_5: number;
    name: string;
    "o/u": string;
    odds: number;
    points_last_10: number;
    points_last_5: number;
    probability: number;
    rebounds_total_last_10: number;
    rebounds_total_last_5: number;
    site: string;
    team: string;
    three_pointers_made_last_10: number;
    three_pointers_made_last_5: number;
    time: string;
    type: string;
}

// Friendly names for table headers
const friendlyNames: { [key: string]: string } = {
    team: "Team",
    name: "Name",
    "o/u": "O/U",
    line: "Line",
    odds: "Odds",
    ev: "EV",
    match: "Match",
    minutes_last_10: "Avg Minutes L10",
    minutes_last_5: "Avg Minutes L5",
    points_last_10: "Avg Points L10",
    points_last_5: "Avg Points L5",
    rebounds_total_last_10: "Avg Rebounds L10",
    rebounds_total_last_5: "Avg Rebounds L5",
    assists_last_10: "Avg Assists L10",
    assists_last_5: "Avg Assists L5",
    three_pointers_made_last_10: "Avg Threes L10",
    three_pointers_made_last_5: "Avg Threes L5",
    site: "Site",
};

// Mapping for team abbreviations
const teamShortNames: { [key: string]: string } = {
    "Hawks": "ATL",
    "Celtics": "BOS",
    "Nets": "BKN",
    "Hornets": "CHA",
    "Bulls": "CHI",
    "Cavaliers": "CLE",
    "Mavericks": "DAL",
    "Nuggets": "DEN",
    "Pistons": "DET",
    "Warriors": "GSW",
    "Rockets": "HOU",
    "Pacers": "IND",
    "Clippers": "LAC",
    "Lakers": "LAL",
    "Grizzlies": "MEM",
    "Heat": "MIA",
    "Bucks": "MIL",
    "Timberwolves": "MIN",
    "Pelicans": "NOP",
    "Knicks": "NYK",
    "Thunder": "OKC",
    "Magic": "ORL",
    "76ers": "PHI",
    "Suns": "PHX",
    "Trail Blazers": "POR",
    "Kings": "SAC",
    "Spurs": "SAS",
    "Raptors": "TOR",
    "Jazz": "UTA",
    "Wizards": "WAS"
};

// Define which fields to show for each tab.
const tabFieldMapping: { [key: string]: string[] } = {
    all: [
        "site", "team", "name", "type", "o/u", "line", "odds", "ev", "match",
        "minutes_last_10", "minutes_last_5", "points_last_10", "points_last_5",
        "rebounds_total_last_10", "rebounds_total_last_5", "assists_last_10", "assists_last_5",
        "three_pointers_made_last_10", "three_pointers_made_last_5",
    ],
    points: [
        "site", "team", "name", "type", "o/u", "line", "odds", "ev", "match",
        "minutes_last_5", "points_last_5",
    ],
    pra: [
        "site", "team", "name", "type", "o/u", "line", "odds", "ev", "match",
        "minutes_last_5", "points_last_5", "rebounds_total_last_5", "assists_last_5",
    ],
    rebounds: [
        "site", "team", "name", "type", "o/u", "line", "odds", "ev", "match",
        "minutes_last_5", "rebounds_total_last_5",
    ],
    assists: [
        "site", "team", "name", "type", "o/u", "line", "odds", "ev", "match",
        "minutes_last_5", "assists_last_5",
    ],
    threes: [
        "site", "team", "name", "type", "o/u", "line", "odds", "ev", "match",
        "minutes_last_5", "three_pointers_made_last_5",
    ],
};

const tabs = [
    { value: "all", label: "All Bets" },
    { value: "points", label: "Points" },
    { value: "pra", label: "Pts+Reb+Ast" },
    { value: "rebounds", label: "Rebounds" },
    { value: "assists", label: "Assists" },
    { value: "threes", label: "Threes" },
];

const NBAPage: React.FC = () => {
    // Data states
    const [nbaData, setNBAData] = useState<NBADataItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    // Use window width to detect mobile and automatically set compact mode on mobile devices.
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const isMobile = windowWidth < 768;
    const [isCompact, setIsCompact] = useState<boolean>(isMobile); // auto-compact on mobile

    // Tab and sorting states
    const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' } | null>({
        key: "ev",
        direction: "desc",
    });
    const [selectedTab, setSelectedTab] = useState<string>('all');

    // Sportsbook selection states
    const [fanDuelSelected, setFanDuelSelected] = useState<boolean>(true);
    const [draftKingsSelected, setDraftKingsSelected] = useState<boolean>(true);

    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchNBAData = useCallback(async () => {
        setLoading(true);
        setError(null);
        let endpoint = "";
        if (fanDuelSelected && draftKingsSelected) {
            endpoint = "/tableValuesNBA";
        } else if (draftKingsSelected) {
            endpoint = "/dkOffersNBA";
        } else if (fanDuelSelected) {
            endpoint = "/fdOffersNBA";
        } else {
            setNBAData([]);
            setLoading(false);
            return;
        }
        try {
            const response = await api.get<NBADataItem[]>(endpoint);
            setNBAData(response.data);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching NBA data:', err);
            setError('Error fetching NBA data');
            setLoading(false);
        }
    }, [fanDuelSelected, draftKingsSelected]);

    useEffect(() => {
        fetchNBAData();
    }, [fetchNBAData]);

    // Filter rows based on the selected tab.
    let filteredData = nbaData;
    if (selectedTab === "points") {
        filteredData = nbaData.filter(item => item.type.toLowerCase() === "points");
    } else if (selectedTab === "pra") {
        filteredData = nbaData.filter(item => item.type.toLowerCase() === "pra");
    } else if (selectedTab === "rebounds") {
        filteredData = nbaData.filter(item => item.type.toLowerCase() === "rebounds");
    } else if (selectedTab === "assists") {
        filteredData = nbaData.filter(item => item.type.toLowerCase() === "assists");
    } else if (selectedTab === "threes") {
        filteredData = nbaData.filter(item => item.type.toLowerCase() === "threes");
    }

    // In compact mode show a shorter list of fields.
    const fieldsToShow = isCompact
        ? ["team", "name", "type", "o/u", "line", "odds", "ev"]
        : tabFieldMapping[selectedTab];

    const sortedData = React.useMemo(() => {
        let sortableData = [...filteredData];
        if (sortConfig !== null) {
            sortableData.sort((a, b) => {
                const aVal = a[sortConfig.key as keyof NBADataItem];
                const bVal = b[sortConfig.key as keyof NBADataItem];
                if (typeof aVal === "number" && typeof bVal === "number") {
                    return sortConfig.direction === "asc" ? aVal - bVal : bVal - aVal;
                }
                const aStr = String(aVal).toLowerCase();
                const bStr = String(bVal).toLowerCase();
                if (aStr < bStr) return sortConfig.direction === "asc" ? -1 : 1;
                if (aStr > bStr) return sortConfig.direction === "asc" ? 1 : -1;
                return 0;
            });
        }
        return sortableData;
    }, [filteredData, sortConfig]);

    const handleSort = (field: string) => {
        if (sortConfig && sortConfig.key === field) {
            setSortConfig({
                key: field,
                direction: sortConfig.direction === "asc" ? "desc" : "asc",
            });
        } else {
            setSortConfig({ key: field, direction: "asc" });
        }
    };

    const getRowClass = (ev: number): string => {
        if (ev >= 0.35) return "table-success";
        else if (ev >= 0.25 && ev < 0.35) return "table-warning";
        else return "table-danger";
    };

    return (
        <div className="page-container" style={{ padding: isMobile ? '0' : '16px' }}>
            {/* Back Button */}
            <div style={{ textAlign: 'left', marginBottom: '16px' }}>
                <button className="modern-button" onClick={() => navigate('/')}>Back</button>
            </div>
            <div className="page-header">
                <h1>NBA Data</h1>
            </div>
            {/* Tabs */}
            <div
                className="tabs-container"
                style={{
                    display: 'flex',
                    gap: '8px',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    marginBottom: '16px'
                }}
            >
                {tabs.map(tabObj => (
                    <button
                        key={tabObj.value}
                        className={`modern-button ${selectedTab === tabObj.value ? 'active-tab' : ''}`}
                        onClick={() => {
                            setSelectedTab(tabObj.value);
                            setSortConfig({ key: "ev", direction: "desc" });
                        }}
                    >
                        {tabObj.label}
                    </button>
                ))}
            </div>
            {/* Sportsbook and Compact View Toggle */}
            <div
                style={{
                    display: 'flex',
                    gap: '16px',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '16px'
                }}
            >
                <div
                    className="sportsbook-container"
                    style={{
                        display: 'flex',
                        gap: '12px',
                        alignItems: 'center',
                        backgroundColor: "var(--secondary-bg)",
                        padding: '8px',
                        borderRadius: '4px'
                    }}
                >
                    <label style={{ fontSize: '14px', color: "var(--primary-bg)", display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            className="custom-checkbox"
                            checked={fanDuelSelected}
                            onChange={(e) => setFanDuelSelected(e.target.checked)}
                        />
                        FanDuel
                    </label>
                    <label style={{ fontSize: '14px', color: "var(--primary-bg)", display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            className="custom-checkbox"
                            checked={draftKingsSelected}
                            onChange={(e) => setDraftKingsSelected(e.target.checked)}
                        />
                        DraftKings
                    </label>
                </div>
                <div
                    className="compact-toggle-container"
                    style={{
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'center',
                        backgroundColor: "var(--secondary-bg)",
                        padding: '8px',
                        borderRadius: '4px'
                    }}
                >
                    <label style={{ fontSize: '14px', color: "var(--primary-bg)", display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            className="custom-checkbox"
                            checked={isCompact}
                            onChange={() => setIsCompact(!isCompact)}
                        />
                        Compact View
                    </label>
                </div>
            </div>
            {loading ? (
                <div className="loading-container">
                    <div className="loading-spinner"></div>
                    <div>Loading...</div>
                </div>
            ) : error ? (
                <div>{error}</div>
            ) : (
                <div
                    className={`table-container ${isCompact ? 'compact' : ''}`}
                    style={{
                        padding: isMobile ? '0' : '10px',
                        background: isMobile ? 'transparent' : 'var(--secondary-bg)'
                    }}
                >
                    <div className="table-responsive-horizontal">
                        <table
                            className={`table ${isCompact ? '' : 'normal-table'}`}
                            style={{ width: isCompact ? 'auto' : '100%' }}
                        >
                            {/* Generate a colgroup so that in compact mode the “small” columns are set to shrink */}
                            <colgroup>
                                {fieldsToShow.map(field => (
                                    <col
                                        key={field}
                                        className={isCompact && ["team", "o/u", "odds", "ev"].includes(field) ? "shrink" : ""}
                                    />
                                ))}
                            </colgroup>
                            <thead>
                                <tr>
                                    {fieldsToShow.map((field: string) => (
                                        <th
                                            key={field}
                                            onClick={() => handleSort(field)}
                                            style={{ cursor: "pointer" }}
                                            className={field === "o/u" ? "ouCell" : ""}
                                        >
                                            {friendlyNames[field] || field}
                                            {sortConfig && sortConfig.key === field
                                                ? sortConfig.direction === "asc"
                                                    ? " ▲"
                                                    : " ▼"
                                                : null}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {sortedData.map((item: NBADataItem) => {
                                    const evValue = Number(item.ev);
                                    const rowClass = getRowClass(evValue);
                                    return (
                                        <tr key={item._id} className={rowClass}>
                                            {fieldsToShow.map((field: string) => {
                                                let cellValue = item[field as keyof NBADataItem];
                                                if (field === "o/u") {
                                                    const ouVal = String(cellValue).toLowerCase();
                                                    cellValue = isCompact
                                                        ? (ouVal === "o" ? "O" : ouVal === "u" ? "U" : ouVal)
                                                        : (ouVal === "o" ? "Over" : ouVal === "u" ? "Under" : ouVal);
                                                } else if (field === "name" && isCompact) {
                                                    const parts = String(cellValue).split(" ");
                                                    cellValue = parts.length >= 2
                                                        ? parts[0].slice(0, 2) + ". " + parts[parts.length - 1]
                                                        : cellValue;
                                                } else if (field === "team" && isCompact) {
                                                    cellValue = teamShortNames[String(cellValue)] || cellValue;
                                                } else if (field === "type") {
                                                    const typeVal = String(cellValue).toLowerCase();
                                                    if (typeVal === "pra") {
                                                        cellValue = isCompact ? "pra" : "Pts+Reb+Ast";
                                                    } else if (typeVal === "points") {
                                                        cellValue = isCompact ? "pts" : "Points";
                                                    } else if (typeVal === "assists") {
                                                        cellValue = isCompact ? "ast" : "Assists";
                                                    } else if (typeVal === "rebounds") {
                                                        cellValue = isCompact ? "reb" : "Rebounds";
                                                    } else if (typeVal === "threes") {
                                                        cellValue = isCompact ? "3pm" : "Threes";
                                                    }
                                                } else if (typeof cellValue === "number") {
                                                    if (field === "line") {
                                                        cellValue = cellValue.toString();
                                                    } else {
                                                        cellValue = Number(cellValue.toFixed(2)).toString();
                                                    }
                                                    if (field === "ev") {
                                                        cellValue = Number(cellValue) - 0.25;
                                                        cellValue = Number(cellValue.toFixed(2)).toString();
                                                    }
                                                }
                                                return (
                                                    <td key={field} className={field === "o/u" ? "ouCell" : ""}>
                                                        {String(cellValue)}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NBAPage;
