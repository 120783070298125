// src/pages/MLBPage.tsx
import React, { useEffect, useState, useCallback } from 'react';
import DataTable, { DataItem } from '../components/DataTable';
import api from '../api/api';
import { useNavigate } from 'react-router-dom';
import '../styles/modern-styles.css'; // Your common modern styles

const MLBPage: React.FC = () => {
    const [tableData, setTableData] = useState<DataItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [fanduelSelected, setFanduelSelected] = useState<boolean>(true);
    const [dkSelected, setDkSelected] = useState<boolean>(true);
    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

    const navigate = useNavigate();

    const fetchData = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            let apiUrl = '';

            if (fanduelSelected && dkSelected) {
                apiUrl = '/tableValues';
            } else if (fanduelSelected) {
                apiUrl = '/fanduelOffers';
            } else if (dkSelected) {
                apiUrl = '/dkOffers';
            }

            if (apiUrl) {
                const response = await api.get<DataItem[]>(apiUrl);
                setTableData(response.data);
            } else {
                setTableData([]);
            }
            setLoading(false);
        } catch (err) {
            console.error('Error fetching data:', err);
            setError('Error fetching data');
            setLoading(false);
        }
    }, [fanduelSelected, dkSelected]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        if (name === 'fanduel') {
            setFanduelSelected(checked);
        } else if (name === 'draftkings') {
            setDkSelected(checked);
        }
    };

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    if (loading) {
        return (
            <div className="loading-container">
                <div className="loading-spinner"></div>
                <div>Loading...</div>
                <div>If this process takes more than 10 seconds, the database is being rebooted.</div>
                <div>Please wait approximately 1 minute.</div>
                <div>If the website still does not load, please contact me @PropHitter on X (formerly Twitter)</div>
            </div>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="page-container">
            {/* Back Button at Top Left */}
            <div style={{ textAlign: 'left', marginBottom: '16px' }}>
                <button className="modern-button" onClick={() => navigate('/')}>Back</button>
            </div>
            <div className="page-header">
                <h1>Hit EVs</h1>
            </div>
            <div className="dropdown-container">
                <button className="dropdown-button" onClick={toggleDropdown}>
                    Sportsbooks <span className="arrow">▼</span>
                </button>
                {dropdownVisible && (
                    <div className="dropdown-content">
                        <label style={{ display: 'block', marginBottom: '10px' }}>
                            <input
                                type="checkbox"
                                name="fanduel"
                                checked={fanduelSelected}
                                onChange={handleCheckboxChange}
                            />
                            FanDuel
                        </label>
                        <label style={{ display: 'block' }}>
                            <input
                                type="checkbox"
                                name="draftkings"
                                checked={dkSelected}
                                onChange={handleCheckboxChange}
                            />
                            DraftKings
                        </label>
                    </div>
                )}
            </div>
            <div className="table-container">
                <div className="table-responsive-horizontal">
                    <DataTable data={tableData} />
                </div>
            </div>
        </div>
    );
};

export default MLBPage;
